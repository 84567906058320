
import * as ipxRuntime$i8Wo83IxfS from '/opt/atlassian/pipelines/agent/build/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as cacheIpxRuntime$qYyuK73J7e from '/opt/atlassian/pipelines/agent/build/providers/cache_ipx.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "modified": {
      "modifiers": {
        "fit": "cover",
        "format": "webp",
        "quality": 80,
        "loading": "lazy"
      }
    }
  },
  "provider": "ipx",
  "domains": [
    "mevcut.co"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$i8Wo83IxfS, defaults: {"maxAge":31536000} },
  ['cacheIpx']: { provider: cacheIpxRuntime$qYyuK73J7e, defaults: {"storageUrl":"https://mevcut.co/storage"} }
}
        